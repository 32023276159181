.root {
  margin-bottom: var(--spacing-12);

  h1,
  h2,
  h3,
  h4 {
    font-weight: bold;
  }

  a {
    text-decoration: underline;

    &:hover {
      color: var(--gray-dark);
    }
  }

  iframe {
    max-width: 100%;
  }

  table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
  }

  thead {
    color: var(--white);
    background-color: var(--gray-dark);
  }

  th,
  td {
    padding: var(--spacing-03) var(--spacing-05);
    font-weight: normal;
  }

  tbody tr:nth-child(2n + 1) {
    background-color: var(--gray-bright);
  }
}
