@use 'styles/base/mixins';

.teaserFirst {
  img {
    @include mixins.devices(tablet-only) {
      aspect-ratio: 16 / 9;
    }

    @include mixins.devices(desktop-only) {
      aspect-ratio: 16 / 9;
    }
  }
}
