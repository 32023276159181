@use 'styles/base/mixins';

.root {
  margin-bottom: var(--spacing-10);
}

.date {
  display: block;
  margin-bottom: var(--spacing-01);
}
