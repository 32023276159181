@use 'styles/base/mixins';

.ol {
  margin-bottom: var(--spacing-10);
  padding: 0;
  list-style: none;
  counter-reset: list-counter 0;

  @include mixins.breakpoint(l) {
    margin-bottom: var(--spacing-16);
  }
}

.li {
  position: relative;
  margin: var(--spacing-05) 0;
  padding-left: var(--spacing-12);
  counter-increment: list-counter 1;

  &::before {
    content: counter(list-counter) '.';
    position: absolute;
    top: 0;
    left: 0;
    font-weight: bold;
  }
}
