.newsCheckWrapper {
  display: grid;
  row-gap: var(--spacing-07);
}

.newsCheckItem {
  display: flex;
  flex-direction: column;
}

.timeWrapper {
  display: flex;
  gap: var(--spacing-04);
  align-items: center;
}

.icon {
  margin-left: var(--spacing-01);
  color: var(--primary);
}

.link {
  margin: var(--spacing-01) var(--spacing-10) 0;

  &:hover {
    text-decoration: underline;
  }
}

.title {
  overflow-wrap: anywhere;
}
