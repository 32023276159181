@use 'styles/base/mixins';

.headline {
  margin-top: var(--spacing-10);
  margin-bottom: var(--spacing-04);

  @include mixins.breakpoint(l) {
    margin-top: var(--spacing-16);
    margin-bottom: var(--spacing-06);
  }
}
