@use 'styles/base/mixins';

.paragraph {
  a {
    text-decoration: underline;

    &:hover {
      color: var(--gray-dark);
    }
  }
}
