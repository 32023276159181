@use 'styles/base/mixins';

.affiliateRoot {
  margin-bottom: var(--spacing-04);
  border-top: 2px solid var(--gray-bright);

  a {
    text-decoration: none;
  }
}

.affiliateLinkContainer {
  width: 100%;
  margin-top: 4px;
  line-height: 20px;
  text-align: right;
}

.affiliateLinkText {
  color: var(--gray-dusky);
  font-size: 11px;
  font-weight: bold;
  text-decoration: underline;
  vertical-align: middle;
}

.affiliateLinkIcon {
  margin-left: 5px;
  color: var(--gray-dusky);
  vertical-align: middle;
}

.inner {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  row-gap: 32px;
  grid-gap: 20px;
}

.productContentHeadlineMobile {
  display: none;
  grid-column-start: span 12;
  margin-bottom: 10px;
  font-size: 17px;

  @include mixins.devices(mobile-only) {
    display: block;
  }
}

.productContentHeadlineDesktop {
  display: block;
  margin-bottom: 30px;
  font-size: 17px;
  font-weight: bold;

  &.amazon {
    margin-bottom: 10px;
  }

  @include mixins.devices(mobile-only) {
    display: none;
  }
}

.productImage {
  position: relative;
  display: flex;
  grid-column-start: span 4;
  justify-content: center;
  width: 100px;
  height: 120px;

  span {
    vertical-align: middle;
  }

  img {
    height: 100%;
    object-fit: contain;
  }

  @include mixins.devices(tablet) {
    width: 170px;
    height: 190px;
  }
}

.productContent {
  display: flex;
  grid-column-start: span 8;
  align-items: center;
}

.productContentPrice {
  display: block;
  margin-top: 8px;
  font-size: 14px;
  font-weight: bold;
}

.productContentPriceCurrent {
  margin-right: 10px;
  color: var(--black);
  text-decoration: none;
}

.productContentPriceBeforeDiscount {
  color: var(--gray-dark);
  text-decoration: line-through;
}

.button {
  display: flex;
  min-width: 48px;
  height: 48px;
  padding: 13px 20px;
  color: var(--white);
  background-color: var(--black);
  font-size: 13px;
  font-weight: normal;
  line-height: 17px;
  vertical-align: middle;
  border-color: var(--black);
  border-style: solid;
  border-width: 2px;
  transition:
    color 250ms ease-in-out,
    background-color 250ms ease-in-out;

  &:hover {
    color: var(--gray-medium);
  }

  @include mixins.devices(tablet) {
    font-size: 17px;
  }
}

.amazonLogo {
  position: relative;
  top: 2px;
  left: 7px;
  display: inline;
}

.ratingContainer {
  position: relative;
  box-sizing: border-box;
  align-items: center;
  margin-bottom: 10px;
}

.ratingStars-1 {
  @include mixins.rating-stars(10%);
}

.ratingStars-2 {
  @include mixins.rating-stars(20%);
}

.ratingStars-3 {
  @include mixins.rating-stars(30%);
}

.ratingStars-4 {
  @include mixins.rating-stars(40%);
}

.ratingStars-5 {
  @include mixins.rating-stars(50%);
}

.ratingStars-6 {
  @include mixins.rating-stars(60%);
}

.ratingStars-7 {
  @include mixins.rating-stars(70%);
}

.ratingStars-8 {
  @include mixins.rating-stars(80%);
}

.ratingStars-9 {
  @include mixins.rating-stars(90%);
}

.ratingStars-10 {
  @include mixins.rating-stars(100%);
}

.ratingCount {
  display: inline-block;
  color: var(--blue);
  font-size: 13px;
}
