@use 'styles/base/mixins';

.root {
  margin-bottom: var(--spacing-10);

  @include mixins.breakpoint(l) {
    margin-bottom: var(--spacing-16);
  }
}

.info {
  padding: var(--spacing-04);
  color: var(--white);
  background-color: var(--black);

  @include mixins.breakpoint(l) {
    padding: var(--spacing-06);
  }
}

.duration {
  margin-bottom: var(--spacing-02);
  color: var(--gray-dark);
}

.subtitle {
  margin-bottom: var(--spacing-02);
}
