@use 'styles/base/mixins';

.grid {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  column-gap: var(--spacing-16);

  > div > * {
    margin-bottom: var(--spacing-08);
  }

  @include mixins.devices(desktopXl) {
    grid-template-columns: minmax(0, 1fr) 300px;
  }
}
