@mixin breakpoint($size) {
  @if $size == xs-only {
    @media only screen and (max-width: 743px) {
      @content;
    }
  }

  @if $size == s {
    @media only screen and (min-width: 744px) {
      @content;
    }
  }

  @if $size == m {
    @media only screen and (min-width: 904px) {
      @content;
    }
  }

  @if $size == l {
    @media only screen and (min-width: 1236px) {
      @content;
    }
  }

  @if $size == xl {
    @media only screen and (min-width: 1376px) {
      @content;
    }
  }

  @if $size == xxl {
    @media only screen and (min-width: 1800px) {
      @content;
    }
  }
}

@mixin devices($breakpoint) {
  @if $breakpoint == mobile-only {
    @media only screen and (max-width: 767px) {
      @content;
    }
  }

  @if $breakpoint == tablet {
    @media only screen and (min-width: 768px) {
      @content;
    }
  }

  @if $breakpoint == tablet-only {
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      @content;
    }
  }

  @if $breakpoint == desktop {
    @media only screen and (min-width: 1024px) {
      @content;
    }
  }

  @if $breakpoint == desktop-only {
    @media only screen and (min-width: 1024px) and (max-width: 1199px) {
      @content;
    }
  }

  @if $breakpoint == desktopXl {
    @media only screen and (min-width: 1200px) {
      @content;
    }
  }
}

@mixin container($breakpoint) {
  @if $breakpoint == mobile-only {
    @supports (container-type: inline-size) {
      @container (max-width: 767px) {
        @content;
      }
    }

    @supports not (container-type: inline-size) {
      @include devices(mobile-only) {
        @content;
      }
    }
  }

  @if $breakpoint == tablet {
    @supports (container-type: inline-size) {
      @container (min-width: 768px) {
        @content;
      }
    }

    @supports not (container-type: inline-size) {
      @include devices(tablet) {
        @content;
      }
    }
  }

  @if $breakpoint == tablet-only {
    @supports (container-type: inline-size) {
      @container (min-width: 768px) and (max-width: 1023px) {
        @content;
      }
    }

    @supports not (container-type: inline-size) {
      @include devices(tablet-only) {
        @content;
      }
    }
  }

  @if $breakpoint == desktop {
    @supports (container-type: inline-size) {
      @container (min-width: 1024px) {
        @content;
      }
    }

    @supports not (container-type: inline-size) {
      @include devices(desktop) {
        @content;
      }
    }
  }

  @if $breakpoint == desktop-only {
    @supports (container-type: inline-size) {
      @container (min-width: 1024px) and (max-width: 1199px) {
        @content;
      }
    }

    @supports not (container-type: inline-size) {
      @include devices(desktop-only) {
        @content;
      }
    }
  }

  @if $breakpoint == desktopXl {
    @supports (container-type: inline-size) {
      @container (min-width: 1200px) {
        @content;
      }
    }

    @supports not (container-type: inline-size) {
      @include devices(desktopXl) {
        @content;
      }
    }
  }
}

@mixin text-display-m {
  font-size: 36px;
  line-height: 38.88px;
  letter-spacing: 0.4px;
}

@mixin text-display-s {
  font-size: 28px;
  line-height: 33.04px;
  letter-spacing: 0.4px;
}

@mixin text-xlarge {
  font-size: 24px;
  line-height: 28.32px;
  letter-spacing: 0.4px;
}

@mixin text-large {
  font-size: 20px;
  line-height: 23.6px;
  letter-spacing: 0.4px;
}

@mixin text-medium {
  font-size: 16px;
  line-height: 21.44px;
  letter-spacing: 0.4px;
}

@mixin text-small {
  font-size: 14px;
  line-height: 18.48px;
  letter-spacing: 0.4px;
}

@mixin text-xsmall {
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0.4px;
}

@mixin rating-stars($percent) {
  display: inline-block;
  margin-right: 8px;
  font-size: 25px;
  line-height: 1;
  vertical-align: middle;

  &::before {
    content: '★★★★★';
    overflow: hidden;
    color: transparent;
    background: linear-gradient(90deg, var(--yellow) $percent, var(--white) $percent);
    filter: drop-shadow(0 0 0.5px var(--black));
    background-clip: text;
    /* stylelint-disable */
    -webkit-background-clip: text;
    /* stylelint-enable */
  }
}

@mixin stretched-link {
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: rgb(0 0 0 / 0%);
    pointer-events: auto;
  }
}

@mixin line-clamp($lines) {
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  overflow: hidden;
  word-break: break-word;
  /* stylelint-enable */
}

@mixin divider {
  content: '';
  position: absolute;
  top: calc(50% - 12px);
  width: 2px;
  height: 24px;
  background-color: var(--gray-bright);
}

@mixin hide-scrollbar {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

@mixin grid {
  @include breakpoint(s) {
    display: grid;
    grid-template-columns: [main-content-column] 648px;
    justify-content: center;
  }

  @include breakpoint(m) {
    grid-template-columns: [main-content-column] 648px [right-content-column] 160px;
    column-gap: var(--spacing-12);
  }

  @include breakpoint(l) {
    grid-template-columns: [main-content-column] 980px [right-content-column] 160px;
  }

  @include breakpoint(xl) {
    grid-template-columns: [main-content-column] 980px [right-content-column] 300px;
  }

  @include breakpoint(xxl) {
    grid-template-columns: [left-content-column] 300px [main-content-column] 1104px [right-content-column] 300px;
  }

  .mainContentZone {
    @include breakpoint(m) {
      grid-column-start: main-content-column;
    }
  }
}
