.mostReadItem {
  display: flex;
  gap: var(--spacing-04);
  min-height: 120px;

  .number {
    min-width: 30px;
    padding-top: var(--spacing-02);
    color: var(--white);
    background-color: var(--black);
  }

  &:nth-of-type(1) {
    .number {
      background-color: var(--primary);
    }
  }

  &:nth-of-type(3) {
    .number {
      opacity: 0.67;
    }
  }

  &:nth-of-type(4) {
    .number {
      opacity: 0.5;
    }
  }

  &:nth-of-type(5) {
    .number {
      opacity: 0.33;
    }
  }
}

.h3 {
  padding-top: var(--spacing-01);
  padding-bottom: var(--spacing-03);

  a {
    display: flex;
    flex-direction: column;

    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }
}

.kicker {
  margin-bottom: var(--spacing-01);
  color: var(--gray-dark);
}

.title {
  overflow-wrap: anywhere;
}
