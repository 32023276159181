@use 'styles/base/mixins';

.root {
  margin-bottom: var(--spacing-16);
  padding: var(--spacing-03);
  border: 2px solid var(--gray-bright);

  @include mixins.devices(tablet) {
    padding: var(--spacing-06);
  }
}

.title,
.kicker {
  color: var(--gray-dark);
}

.item:not(:last-child),
.title {
  display: block;
  margin-bottom: var(--spacing-03);

  @include mixins.devices(tablet) {
    margin-bottom: var(--spacing-06);
  }
}
