@use 'styles/base/mixins';

.grid {
  @include mixins.grid;
}

.main {
  @include mixins.breakpoint(xs-only) {
    padding: 0 var(--spacing-06);

    > * {
      margin-bottom: var(--spacing-16);
    }
  }

  @include mixins.breakpoint(s) {
    > * {
      margin-bottom: var(--spacing-24);
    }
  }

  &.overflowHidden {
    overflow-x: hidden;
  }
}

.asideRight {
  display: flex;
  overflow: hidden;
}
