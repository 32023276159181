@use 'styles/base/mixins';

.grid {
  @include mixins.devices(desktop) {
    display: grid;
    grid-template-columns: [main-content-column] 800px [right-content-column] auto;
    justify-content: start;
    column-gap: var(--spacing-08);
  }

  @include mixins.devices(desktopXl) {
    grid-template-columns:
      [left-content-column] minmax(0, 300px) [main-content-column] minmax(800px, 1200px)
      [right-content-column] minmax(0, 300px);
    justify-content: center;
  }
}

.mainContentZone {
  @include mixins.devices(desktop) {
    grid-column-start: main-content-column;
  }
}

.main {
  padding: 0 var(--spacing-04);

  @include mixins.devices(mobile-only) {
    > * {
      margin-bottom: var(--spacing-16);
    }
  }

  @include mixins.devices(tablet) {
    > * {
      margin-bottom: var(--spacing-24);
    }
  }

  @include mixins.devices(desktopXl) {
    padding: 0;
  }

  &.overflowHidden {
    overflow-x: hidden;
  }
}

.asideRight {
  display: flex;
  overflow: hidden;

  @include mixins.devices(desktop) {
    padding-left: var(--spacing-05);
  }
}

.asideLeft {
  display: flex;
  overflow: hidden;
}
