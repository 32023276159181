.box {
  margin-bottom: var(--spacing-10);
}

.entry {
  display: flex;
  margin-bottom: var(--spacing-05);
}

.label {
  display: inline-block;
  width: 120px;
}
