.article-head {
  display: flex;
  gap: var(--spacing-05);
  flex-direction: column;
}

.image-info {
  padding: var(--spacing-06);
  color: var(--white);
  background: var(--black);
}
