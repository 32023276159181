@use 'styles/base/mixins';

.lead {
  margin-bottom: var(--spacing-10);
  padding-left: var(--spacing-04);
  border-left: 2px solid var(--black);

  @include mixins.breakpoint(l) {
    margin-bottom: var(--spacing-16);
    padding-left: var(--spacing-05);
  }
}
