@use 'styles/base/mixins';

.stickyColumn {
  position: sticky;
  top: 140px;
  align-self: start;

  :global(.adwrapper) {
    margin-bottom: 0;
  }
}

.grid {
  position: relative;
  display: grid;
  gap: var(--spacing-08);

  @include mixins.breakpoint(l) {
    grid-template-columns: minmax(10px, 2fr) minmax(10px, 1fr);
    gap: var(--spacing-10);

    &:has([data-id='section-title']) {
      .stickyColumn {
        top: calc(140px - var(--spacing-14));
        padding-top: var(--spacing-14);
      }
    }
  }
}
