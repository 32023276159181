@use 'styles/base/mixins';

.grid {
  display: flex;
  gap: var(--spacing-08);
  flex-direction: column;

  @include mixins.breakpoint(l) {
    gap: var(--spacing-10);
  }
}
