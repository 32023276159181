@use 'styles/base/mixins';

.ul {
  margin-bottom: var(--spacing-10);
  padding: 0;
  list-style: none;

  @include mixins.breakpoint(l) {
    margin-bottom: var(--spacing-16);
  }
}

.li {
  position: relative;
  margin: var(--spacing-05) 0;
  padding-left: var(--spacing-12);

  &::before {
    content: '';
    position: absolute;
    top: 9px;
    left: 0;
    width: 9px;
    height: 9px;
    background-color: var(--primary);
    border-radius: 50%;
  }
}
