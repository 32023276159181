@use 'styles/base/mixins';

.grid {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  column-gap: var(--spacing-16);

  @include mixins.devices(desktopXl) {
    grid-template-columns: 300px minmax(0, 1fr);
  }
}

.sidebar {
  display: flex;
  gap: var(--spacing-02);
  flex-direction: column;
}

.image {
  border-radius: 50%;
}
